import React, { FC, useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import moment from 'moment-timezone'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { isMobile } from 'react-device-detect'

import Layout from '../components/Layout'
import extractGlobals from '../lib/extractGlobals'
import { Maybe, EventPostTemplateQuery } from '../../graphql-types'
import PortableText from 'react-portable-text'
import { styled, spaces, mq, typography, colors } from '../styles'
import locationIcon from '../images/locationIcon.png'
import odaLogo from '../images/logo.png'
import { InternalLinkButton } from '../components/Button'
import { getSeasonFromMonth } from '../lib/EventUtils'

type EventPostType = EventPostTemplateQuery['post']

const findPreviousAndNextPosts = (
  slug: string | undefined | null,
  allPosts: (Maybe<string> | undefined)[],
) => {
  if (!slug || !allPosts) return { previous: -1, next: -1 }
  const postIndex = allPosts.indexOf(slug)
  const previousIndex =
    postIndex - 1 < 0
      ? allPosts.length === 2
        ? -1
        : allPosts.length - 1
      : postIndex - 1
  const nextIndex =
    postIndex + 1 >= allPosts.length
      ? allPosts.length === 2
        ? -1
        : 0
      : postIndex + 1
  return { previous: previousIndex, next: nextIndex }
}

const EventPostTemplate: FC<PageProps<EventPostTemplateQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  const post = data && data.post
  const allSanityEventPost = data && data.allSanityEventPost

  const [previousPost, nextPost] = useMemo(() => {
    const allPostSlugs = allSanityEventPost.nodes.map(
      (node) => node.slug?.current,
    )
    const allPostNames = allSanityEventPost.nodes.map((node) => node.name)

    const { previous, next } = findPreviousAndNextPosts(
      post?.slug?.current,
      allPostSlugs,
    )
    return [
      previous > -1
        ? { name: allPostNames[previous], slug: allPostSlugs[previous] }
        : undefined,
      next > -1
        ? { name: allPostNames[next], slug: allPostSlugs[next] }
        : undefined,
    ]
  }, [allSanityEventPost, post?.slug])

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <BackButton>
        <Link to="/archive">Back to archive</Link>
      </BackButton>
      <PostNavigation previousPost={previousPost} nextPost={nextPost} />
      {/*
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}
      {post && <BlogPost {...post} />} */}
      <EventPost post={post} />
      <PostNavigation previousPost={previousPost} nextPost={nextPost} />
      <BackButtonAtBottom>
        <Link to="/archive">Back to archive</Link>
      </BackButtonAtBottom>
    </Layout>
  )
}

interface PostNavProp {
  name: Maybe<string> | undefined
  slug: Maybe<string> | undefined
}

const PostNavigation: FC<{
  previousPost?: PostNavProp
  nextPost?: PostNavProp
}> = ({ previousPost, nextPost }) => {
  return (
    <NavButtons hasTwo={previousPost !== undefined && nextPost !== undefined}>
      {previousPost && (
        <PostLink to={`/archive/${previousPost.slug}`}>
          {previousPost.name}
        </PostLink>
      )}
      {nextPost && (
        <PostLink to={`/archive/${nextPost.slug}`}>{nextPost.name}</PostLink>
      )}
    </NavButtons>
  )
}

const BackButton = styled.div({
  margin: '15px 20px 0',
})

const BackButtonAtBottom = styled.div({
  margin: '0 20px 20px',
})

const NavButtons = styled('div')<{ hasTwo: boolean }>(
  {
    display: 'flex',
  },
  (props) => ({
    justifyContent: props.hasTwo ? 'space-between' : 'flex-end',
  }),
)

const PostLink = styled(InternalLinkButton)(
  {
    display: 'flex',
    backgroundColor: 'transparent',
    border: '1px solid black',
    color: 'black',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  mq({
    fontSize: 16,
    paddingTop: 5,
    margin: [20, 15],
  }),
)

interface EventPostProps {
  post: EventPostType
}

const EventPost: FC<EventPostProps> = ({ post }) => {
  const startTime = moment(post?.event?.startTime)
  const endTime = moment(post?.event?.endTime)
  const startTimeDay = startTime.format('MMMM Do')
  const endTimeDay = endTime.format('MMMM Do')

  return (
    <Container>
      <TextContainer>
        <Title>{post?.name}</Title>

        <LocationContainer>
          <LocationIconImage src={locationIcon} alt={'location icon'} />
          <div>{post?.event?.location}</div>
        </LocationContainer>

        <Metadata>
          <MetadataBlock>
            <OdaLogoImage src={odaLogo} alt={'Oda logo'} />
          </MetadataBlock>
          <MetadataTimeBlock>
            <MetadataBlockTitle>SEASON</MetadataBlockTitle>
            {getSeasonFromMonth(startTime)}
          </MetadataTimeBlock>
          <MetadataTimeBlock>
            <MetadataBlockTitle>YEAR</MetadataBlockTitle>
            {startTime.format('YYYY')}
          </MetadataTimeBlock>
          <MetadataDayBlock>
            <MetadataBlockTitle>DAY</MetadataBlockTitle>
            {startTimeDay === endTimeDay
              ? startTimeDay
              : `${startTimeDay} to ${endTimeDay}`}
          </MetadataDayBlock>
        </Metadata>

        <DescriptionText>
          <PortableText
            // Pass in block content straight from Sanity.io
            content={post?._rawDescription}
            // Optionally override marks, decorators, blocks, etc. in a flat
            // structure without doing any gymnastics
            serializers={{
              blockquote: (props) => <QuoteText {...props} />,
            }}
          />
        </DescriptionText>
      </TextContainer>

      {post?.blocks?.map((block, index) => (
        <ContentBlock key={index}>
          {block?.content?.map((content, cIndex) => {
            const elementCount = block?.content?.length
            if (content && 'title' in content) {
              return (
                <ContentSubBlock
                  alignment={block.alignment}
                  key={`sub${cIndex}`}>
                  {content.image?.asset && (
                    <ContentBlockImageContainer
                      single={elementCount === 1}
                      small={false}>
                      <GatsbyImage
                        image={content.image?.asset?.gatsbyImageData}
                        alt={'journey image'}
                      />
                      <ContentBlockTitle>{content.title}</ContentBlockTitle>
                      <div>{content.body}</div>
                      <ContentTimeStamp>
                        {moment(content.publishedAt).format('M/D h:mm a')}
                      </ContentTimeStamp>
                    </ContentBlockImageContainer>
                  )}
                </ContentSubBlock>
              )
            } else if (content && 'description' in content) {
              if (content.description && !content.image) {
                return (
                  <ContentSubBlock
                    key={`sub${cIndex}`}
                    alignment={block.alignment}>
                    <ContentBlockDescription>
                      {content.description}
                    </ContentBlockDescription>
                  </ContentSubBlock>
                )
              } else if (content.image) {
                return (
                  <ContentSubBlock
                    key={`sub${cIndex}`}
                    alignment={block.alignment}>
                    <ContentBlockImageContainer
                      single={elementCount === 1}
                      small={content.imageIsSmall}>
                      <GatsbyImage
                        image={content.image?.asset?.gatsbyImageData}
                        imgStyle={{
                          maxHeight: isMobile ? 700 : 600,
                          objectFit: isMobile ? 'contain' : 'contain',
                        }}
                        alt={'journey image'}
                      />
                    </ContentBlockImageContainer>
                    {content.description && content.description !== ' ' && (
                      <ContentBlockDescription>
                        {content.description}
                      </ContentBlockDescription>
                    )}
                  </ContentSubBlock>
                )
              }
            }
          })}
        </ContentBlock>
      ))}
    </Container>
  )
}

const Container = styled.div(
  {
    margin: 'auto',
  },
  mq({
    maxWidth: 1000,
    paddingBottom: spaces.medium,
  }),
)

const Title = styled.div(
  typography.extraLargeSerif,
  { textAlign: 'center', whiteSpace: 'pre-wrap' },
  mq({
    paddingBottom: spaces.medium,
    paddingLeft: [0, 20],
    paddingRight: [0, 20],
  }),
)

const TextContainer = styled.div(
  typography.tinyMono,
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  mq({
    maxWidth: 780,
    paddingTop: spaces.large,
    paddingBottom: spaces.large,
  }),
)

const DescriptionText = styled.div(
  typography.tinyMono,
  { textAlign: 'left' },
  mq({
    paddingLeft: [0, 20],
    paddingRight: [0, 20],
  }),
)

const QuoteText = styled.p({ color: colors.goldenBrown })

const LocationContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mq({
    paddingBottom: spaces.medium,
  }),
)

const LocationIconImage = styled.img({
  width: 12,
  height: 16,
  marginRight: 5,
  marginTop: 2,
})

const Metadata = styled.div(
  {
    border: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  mq({
    marginBottom: spaces.medium,
    width: [600, '90%'],
    height: 70,
  }),
)

const MetadataBlock = styled.div({
  borderRight: '1px solid black',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
})

const MetadataTimeBlock = styled(MetadataBlock)({
  paddingTop: 10,
})

const MetadataDayBlock = styled(MetadataBlock)({
  borderRight: 'none',
  flex: 2,
  paddingTop: 10,
})

const MetadataBlockTitle = styled.div({
  position: 'absolute',
  top: 0,
  left: 6,
})

const OdaLogoImage = styled.img({
  width: 40,
})

const ContentBlock = styled.div(
  typography.tinyMono,
  { display: 'flex' },
  mq({
    marginBottom: [100, 0],
    flexDirection: ['row', 'column'],
  }),
)

const ContentSubBlock = styled.div<{ alignment?: string | null }>(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  (props) => ({
    alignItems:
      props.alignment === 'left'
        ? 'flex-start'
        : props.alignment === 'right'
        ? 'flex-end'
        : 'center',
  }),
  mq({
    padding: [0, '0 20px 20px'],
  }),
)

const ContentBlockImageContainer = styled.div<{
  single: boolean
  small?: boolean | null
}>(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  (props) =>
    mq({
      maxWidth: props.single
        ? props.small
          ? [280, '100%']
          : 600
        : props.small
        ? [180, '100%']
        : 450,
    }),
)

const ContentBlockTitle = styled.div({ paddingTop: 5 })

const ContentBlockDescription = styled.div(
  { whiteSpace: 'pre-wrap' },
  mq({
    padding: [60, '60px 30px'],
  }),
)

const ContentTimeStamp = styled.div({
  paddingTop: 5,
  textAlign: 'end',
})

export const query = graphql`
  query EventPostTemplate($id: String!) {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    allSanityEventPost(sort: { fields: publishedAt, order: DESC }) {
      totalCount
      nodes {
        name
        slug {
          current
        }
      }
    }
    post: sanityEventPost(id: { eq: $id }) {
      id
      name
      slug {
        current
      }
      event {
        name
        description
        endTime
        startTime
        location
        type
      }
      _rawDescription
      blocks {
        content {
          ... on SanityEventPostContent {
            description
            image {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 700
                )
                url
              }
            }
            imageIsSmall
          }
          ... on SanityAppFeedItem {
            title
            body
            publishedAt
            image {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 700
                )
                url
              }
            }
          }
        }
        alignment
      }
    }
  }
`

export default EventPostTemplate
